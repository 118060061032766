import { bindable } from 'aurelia-framework';
import './comments.scss';

export class Comments {

  @bindable comments;
  @bindable comment;
  @bindable readonly;
  @bindable autofocus;
  
  constructor() {
    this.comments = [];
    this.commentValue = null
    this.readonly = false;
    this.autofocus = false;

    //binding to textarea not working?!!
    this.onCommentChanged = this.onCommentChanged.bind(this);
  }
  
  commentsChanged() {
    //todo
    // this.dummyComments();
    if (this.comments && this.comments.length) {
      this.comment = null;
      this.comments.forEach(c => {
        c.lnCount = ((c.content||'').match(/\n/g)||[]).length,
        c.fullName = c.name || c.username;
      })
    }

  }

  commentChanged(value) {
    this.commentValue = value ? value.content || null : null;
  }

  onCommentChanged(e) {
    this.comment = { content: this.commentValue };
  }
}
