import {
  bindable,
  computedFrom
} from "aurelia-framework";

import './alert.scss';

export class Alert {
  @bindable message;
  @bindable type;

  constructor() {}

  @computedFrom('type')
  get icon() {
    switch (this.type) {
      case 'info':
        return 'info';
      case 'success':
        return 'check-circle';
      case 'danger':
        return 'alert-triangle';
      case 'warning':
        return 'alert-circle';
      default:
        return '';
    }
  }
}
